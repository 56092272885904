<template>
  <div
    v-if="data.fields"
  >
    <div class="two-columns-content delay-entry"
      @click="data.fields.ctaTarget && !data.fields.cta ? goToLink(data.fields.ctaTarget) : ''"
    >
      <div class="img-wrapper" v-if="data.fields.image">
        <div class="img" :style="{backgroundImage: `url(${data.fields.image.fields.file.url})`}">
          <h4 v-if="data.fields.headline">{{data.fields.headline}}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export default {
  name: 'ImageLink',

  transition: 'page',

  props: [ 'data' ],

  methods: {
    goToLink (target) {
      if (target.indexOf('https://') === 0 || target.indexOf('http://') === 0) {
        window.location.href = target
        return
      }
      this.$router.push(target)
    },
    mdToHTML (markdown) {
      return documentToHtmlString(markdown)
    }
  },
}
</script>

<style scoped lang="scss">
.img-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;

  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }
  h4 {
    text-align: center;
    background: $text-main;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    margin: 0 !important;
    text-transform: none;
    letter-spacing: unset;
    // new fixes
    height: 40px;
    line-height: 20px;
    letter-spacing: unset;
    padding: 2%;
    width: calc(100% - 4%);
  }
}
.two-columns-wrapper {
  min-height: 200px;
  @include breakpoint($tablet) {
    width: 50%;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.two-columns-background-color-light {
    background-color: rgba($gray-light, 0.6);
  }
  &.two-columns-background-color-Dark {
    background-color: rgba($text-main, 0.99);
    h4 {
      color: rgba(white, 0.99);
    }
    h2 {
      color: rgba(white, 0.5);
    }
  }
}
.two-columns-content {
  padding: 1em;
  position: relative;
  text-align: left;
  @include breakpoint($tablet) {
    padding: 2em 1em;
  }
  p {
    @include breakpoint($tablet) {
      font-size: 0.6em;
    }
  }
  &.remove-padding-top {
    padding-top: 0;
  }
  &.remove-padding-bottom {
    padding-bottom: 0;
  }
}
.website-container {
  h4 {
    margin-bottom: 1em;
  }
}

.column-image {
  max-width: 100%;
  height: auto;
  width: 100%;
  margin-bottom: 1em;
}
</style>
<style lang="scss">
.two-columns + .two-columns {
  padding-top: 0;
  margin-bottom: 1em;
}

.two-columns-content {
  .description {
    h4 {
      margin-top: 4em;
      margin-bottom: 1em;
    }
  }
}
</style>